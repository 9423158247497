import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { Stack } from "@mui/material";

import theme from "../../styles/theme";
import RoundedButton from "../../components/elements/RoundedButton";
import LittleBandBrand from "../../components/brand/brand/LittleBandBrand";
import LittleArtistBrand from "../../components/brand/brand/LittleArtistBrand";
import CreativeLab from "../../components/brand/brand/CreativeLab";
import ContainerLayout from "../../components/layouts/ContainerLayout";

const MENUS = [
    { title: "어린음악대", color: theme.palette.littleBand.main },
    {
        title: "어린화가들",
        color: theme.palette.littleArtist.main,
    },
    { title: "창의연구소", color: "#fbc101" },
];

export default function Brand() {
    const [selectedMenu, setSelectedMenu] = useState("어린음악대");

    return (
        <>
            <Helmet>
                <title>주은교육 브랜드 소개</title>
                <meta name="description" content="어린음악대, 어린화가들, 창의연구소 소개" />
                {/* 파비콘 */}
                <link rel="icon" type="image/x-icon" href="/favicon/jueun-edu/favicon-16x16.ico" />
                {/* OG 태그 */}
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="ko_KR" />
                <meta property="og:url" content="https://jueun-edu.com/brand" />
                <meta property="og:site_name" content="주은교육" />
                <meta property="og:title" content="주은교육 브랜드 소개" />
                <meta
                    property="og:description"
                    content="주은교육(주) 공식 홈페이지입니다. 유아동 예술 교육 전문 기업으로 ‘어린음악대’와 ‘어린화가들’ 학원 프랜차이즈를 운영하고 있습니다. 주은교육은 음악교육을 통해 아이들이 풍부한 감성을 기르고 내면의 소리에 귀를 기울여 자신을 사랑하는 건강한 자존감을 가지도록 돕는 것을 목표로 하고 있습니다."
                />
                <meta property="og:image" content="https://jueun-edu.com/images/og-jueun-edu.png" />
                {/* 트위터 OG 태그 */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="주은교육 브랜드 소개" />
                <meta
                    name="twitter:description"
                    content="주은교육(주) 공식 홈페이지입니다. 유아동 예술 교육 전문 기업으로 ‘어린음악대’와 ‘어린화가들’ 학원 프랜차이즈를 운영하고 있습니다. 주은교육은 음악교육을 통해 아이들이 풍부한 감성을 기르고 내면의 소리에 귀를 기울여 자신을 사랑하는 건강한 자존감을 가지도록 돕는 것을 목표로 하고 있습니다."
                />
                <meta name="twitter:image" content="https://jueun-edu.com/images/og-jueun-edu.png" />
            </Helmet>
            {/* 메인 desc */}
            <Stack>
                <ContainerLayout
                    sx={{
                        position: "sticky",
                        top: "-40px",
                        zIndex: 1,
                        pb: 1,
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        backdropFilter: "blur(30px)",
                    }}>
                    {/* 소메뉴 */}
                    <Stack flexDirection="row" justifyContent="center" sx={{ mt: "48px" }}>
                        {MENUS.map((menu, index) => {
                            return (
                                <RoundedButton
                                    key={index}
                                    onClick={() => {
                                        setSelectedMenu(menu.title);
                                        const ele = document.getElementById("content");
                                        ele.scrollIntoView({ behavior: "smooth" });
                                    }}
                                    variant={selectedMenu === menu.title ? "contained" : "outlined"}
                                    otherColor={menu.color}
                                    bgcolor={menu.color}
                                    otherHoverColor="white"
                                    stickyColor
                                    sx={{
                                        ml: index === 1 ? "8px" : 0,
                                        mr: index === 1 ? "8px" : 0,
                                    }}>
                                    {menu.title}
                                </RoundedButton>
                            );
                        })}
                    </Stack>
                </ContainerLayout>
                {/* 컨텐츠 */}
                <Stack id="content" sx={{ pt: 8 }}>
                    {selectedMenu === "어린음악대" && <LittleBandBrand />}
                    {selectedMenu === "어린화가들" && <LittleArtistBrand />}
                    {selectedMenu === "창의연구소" && <CreativeLab />}
                </Stack>
            </Stack>
        </>
    );
}
